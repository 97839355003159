.dashboard_container {
	position: relative;
	.dashboard_container_loader {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;    
		z-index: 10;
		background: #e9e9e982;
	}
	padding: 20px;
	background-color: #f4f5f8 !important;
	height: calc(100vh - 65px);
	overflow-y: auto;
	.title {
		font-size: 1.4rem;
		color: #1d1b51;
		font-weight: bold;
	}
	.dashboard_card {
		background: white;
		height: 100%;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		border-radius: 6px;
		padding: 20px;
		cursor: pointer;
		transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
		&:hover {
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		}
		.icon {
			background-color: #e8f9ec;
			height: 35px;
			width: 35px;
			border-radius: 20px;
			font-size: 1.1rem;
			color: #5b9367;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.red {
			background-color: #fdf3f4 !important;
			color: #c68288 !important;
		}
		.value {
			font-size: 1.35rem;
			color: #1d1b51;
			font-weight: bold;
		}
		.name {
			font-size: 0.8rem;
			color: #9298a8;
			font-weight: 600;
		}
		.desc {
			padding-top: 10px;
			font-size: 0.7rem;
			color: #1d1b5168;
			font-weight: 400;
		}
	}
	.dashboard_card_container {
		padding: 20px;
		&:nth-child(1) {
			display: none !important;
			.dashboard_container_card {
				background: radial-gradient(#1fe4f5, #3fbafe);
			}
		}
		&:nth-child(2) {
			.dashboard_container_card {
				background: radial-gradient(#fbc1cc, #fa99b2);
			}
		}
		&:nth-child(3) {
			.dashboard_container_card {
				background: radial-gradient(#76b2fe, #b69efe);
			}
		}
		&:nth-child(4) {
			.dashboard_container_card {
				background: radial-gradient(#60efbc, #58d5c9);
			}
		}
		&:nth-child(5) {
			.dashboard_container_card {
				background: radial-gradient(#f588d8, #c0a3e5);
			}
		}
		&:nth-child(6) {
			.dashboard_container_card {
				background: radial-gradient(#1fe4f5, #3fbafe);
			}
		}
		&:nth-child(7) {
			.dashboard_container_card {
				background: radial-gradient(#76b2fe, #b69efe);
			}
		}
	}
	&_card {
		border-radius: 10px;
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
		transition: all 0.2s;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 13vh;
		transform: scale(0.95);
		color: #fff;
		font-size: 1.5rem;
		.name {
			font-size: 1.2rem;
		}
		&:hover {
			box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
			transform: scale(1);
		}
	}
}

#payments {
	// border-collapse: collapse;
	// width: 100%;
	// font-size: 1rem;
	// border: none;
	// box-shadow: rgb(149 157 165 / 20%) 0px 0px 12px;
	// background-color: #eee;
	// tr {
	// 	&:hover {
	// 		cursor: pointer;
	// 	}
	// 	&:last-child {
	// 		td,
	// 		th {
	// 			border-bottom: none;
	// 		}
	// 	}
	// 	th {
	// 		// border-bottom: 1px solid #ddd;
	// 		padding-top: 12px !important;
	// 		padding-bottom: 12px !important;
	// 		text-align: left;
	// 		// background-color: #efefef;
	// 		// font-weight: bold !important;
	// 		color: black;
	// 		background-color: #eee;
	// 		border-radius: 5px;
	// 	}
	// 	td,
	// 	th {
	// 		font-weight: normal;
	// 		font-size: 0.8rem;
	// 		// border-bottom: 1px solid #ddd;
	// 		padding: 12px 12px;
	// 		// width: 20%;
	// 		// border: 1px solid #ddd;
	// 		// &:nth-child(1) {
	// 		// 	width: 20%;
	// 		// }
	// 	}
	// 	td {
	// 		background-color: #fff;
	// 		&:first-child {
	// 			// font-weight: bold;
	// 			// color: rgba(29, 27, 81, 0.4078431373);
	// 		}
	// 	}
	// }
}

.dashboard-table {
	border-collapse: collapse;
	width: 100%;
	font-size: 1rem;
	border: none;
	// box-shadow: rgb(149 157 165 / 20%) 0px 0px 12px;
	// border-bottom: 1px solid #ececec;
	background-color: #eee;
	border-radius: 4px;
	overflow: hidden;
	tr {
		border-radius: 4px;
		border-bottom: 1px solid #ececec;
		&:hover {
			cursor: pointer;
		}
		td {
			font-weight: normal;
			font-size: 0.8rem;
			padding: 8px 10px;
			background-color: #fff;
		}
	}

	.footer {
		border-top: 1px solid #ececec;
		span {
			font-weight: bold;
		}
	}
}

.scroll_table_container {
	.scroll_table {
		max-height: 250px;
		min-height: 250px;
		background: #fff;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 2px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #d1d2d2;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #f4f5f8;
		}
	}
	.scroll_table_footer {
		background-color: #fff;
		border-top: 1px solid #ececec;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 8px;
		div {
			span {
				font-size: 0.8rem;
				padding: 8px 10px;
				font-weight: bold;
			}
		}
	}
}
